.move-to-top {
    position: relative;
    top: -130px;
    /* top: -78px; */
    margin-bottom: -100px;
    /* Compensate for the space left by moving the div */
}

/* Add responsive styles */
@media (max-width: 768px) {
    .move-to-top {
        top: -50px;
        /* Adjust as needed */
        margin-bottom: -50px;
        /* Adjust as needed */
    }
}

@media (max-width: 576px) {
    .move-to-top {
        top: -30px;
        /* Adjust as needed */
        margin-bottom: -30px;
        /* Adjust as needed */
    }
}

@media (max-width: 576px) {
    .alignicons {
        margin-left: -3%;
    }
}

@media (max-width: 576px) {
    .previewBtn {
        margin-Top: 1%;
    }
}

.AutoComplete {
    margin-left: 5%;
}

/* Add responsive styles */
@media (max-width: 768px) {
    .AutoComplete {
        margin-left: 5%;
    }
}

@media (max-width: 576px) {
    .AutoComplete {
        margin-left: 0%;
    }
}

.HeadingBlogList {
    margin-left: 10%;
}

/* Add responsive styles */
@media (max-width: 768px) {
    .HeadingBlogList {
        margin-left: 10%;
    }
}

@media (max-width: 576px) {
    .HeadingBlogList {
        margin-left: 0%;
    }
}

.ql-toolbar.ql-snow {
    background-color: #ccc;
}

/* landing screen*/

.rounded-1 {
    background-color: #1A1D29;
    /* background-color: #922022; */
}

.rounded-2 {
    /* background-color: #1A1D29; */
    /* background-color: #922022; */
    background-color: #14171F;
}

.accordion-header {
    font-size: 16px !important;
    font-weight: bold;
}

.faqheading {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    border-radius: 6px;
    background-color: #1A1D29;
    color: #F6F7F9;
    border: 1px solid #1A1D29;
    padding: 1%;
}

@media (max-width: 767px) {
    .wide-on-mobile {
        width: 100%;
        max-width: none;
    }
}

/*  */

/* New Campaign screen */

.accordion_style1 {
    font-size: 20px !important;
    color: #344054;
    font-weight: bold;
    background-color: #D0D5DD;
    border-radius: 5px;
}

/*  */

/* still working loader css */
.loader {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
    0% {
        box-shadow: 20px 0 #4BA5EB, -20px 0 #4BA5EB33;
        background: #4BA5EB;
    }

    33% {
        box-shadow: 20px 0 #4BA5EB, -20px 0 #4BA5EB33;
        background: #4BA5EB33;
    }

    66% {
        box-shadow: 20px 0 #4BA5EB33, -20px 0 #4BA5EB;
        background: #4BA5EB33;
    }

    100% {
        box-shadow: 20px 0 #4BA5EB33, -20px 0 #4BA5EB;
        background: #4BA5EB;
    }
}

/*  */

/* unsplash full preview image on mouse hover */
.thumbnail-container {
    position: relative;
    display: inline-block;
}

.thumbnail {
    z-index: 1;
    transition: opacity 0.3s ease;
}

.full-image {
    position: absolute;
    top: 0;
    left: -100%;
    width: auto;
    height: auto;
    max-width: 400px;
    max-height: 300px;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.thumbnail-container:hover .full-image {
    opacity: 1;
}

.thumbnail-container:hover .thumbnail {
    opacity: 0;
}

/*  */

/* new landing transition effect */

.section {
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease;
    transform: translateY(20px);
}

.section.visible {
    opacity: 1;
    transform: translateY(0);
}

/*  */

/* new landing responsive */
/* @media (max-width: 1024px) {
    .responsive-p-custopti {
        width: 100% !important;
    }
} */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .responsive-col-podcastsec {
        width: 84% !important;
        margin-left: 5% !important;
    }

    .responsive-p-custopti {
        width: 100% !important;
    }
}

/*  */

.overlay-container {
    position: relative;
    background-color: #1F1F27;
}

.overlay-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.1); */
    background-color: #24242C;
    pointer-events: none;
    z-index: 1;
}

.overlay-container>* {
    position: relative;
    z-index: 2;
}

/* horizontal Timeline Bulletings */
.timeline {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    width: 100%;
}

.timeline::before {
    content: "";
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #922022;
    z-index: 1;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 20%;
    background-color: #922022;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    z-index: 2;
}

.vertical-line {
    width: 2px;
    height: 50px;
    background-color: #922022;
    z-index: 1;
}

.additional-text {
    margin-top: 10px;
    font-size: 14px;
    color: white;
    max-width: 180px;
}

/*  */

/* Vertical Timeline */
.timeline1 {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    align-items: left;
    position: relative;
    width: 100%;
}

.timeline1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 3%;
    width: 2px;
    height: 100%;
    background-color: #922022;
    z-index: 1;
    transform: translateX(-50%);
}

@media (max-width: 768px) {
    .timeline1::before {
        left: 4%;
        transform: none;
    }
}

.step1 {
    display: flex;
    align-items: center;
    position: relative;
    margin: 40px 0;
}

.circle-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
}

.circle1 {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    /* Square with rounded corners */
    background-color: #922022;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    z-index: 2;
}

.line1 {
    position: absolute;
    top: 50%;
    left: 100%;
    width: 50px;
    height: 2px;
    background-color: #922022;
    transform: translateY(-50%);
}

.text1 {
    margin-left: 70px;
    font-size: 16px;
    color: white;
    font-weight: bold;
    /* max-width: 280px; */
}

/*  */

/* Aboutus Vertical Timeline */
.timeline2 {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.timeline2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #BDB8DF;
    z-index: 1;
    transform: translateX(-50%);
}

.step2 {
    display: flex;
    align-items: center;
    position: relative;
    margin: 40px 0;
    justify-content: space-between;
}

.step2:nth-child(odd) {
    flex-direction: row-reverse;
    text-align: right;
}

.circle-container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
}

.circle2 {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #E9E6FA;
    color: #2A2742;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    z-index: 2;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.line2 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 2px;
    background-color: #BDB8DF;
    transform: translateX(-50%);
}

.text2 {
    width: 45%;
    font-size: 16px;
    color: #2A2742;
    font-weight: bold;
}

@media (max-width: 768px) {
    .timeline2::before {
        left: 5%;
    }
    
    .step2 {
        flex-direction: column !important;
        text-align: left;
        align-items: flex-start;
    }
    
    .text2 {
        width: 90%;
        margin-top: 10px;
    }
}

/*  */

/* chevron bulleting(like arrow downward) */
.chevron-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;
}

.chevron-item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: -46px;
}

.chevron {
    width: 92px;
    height: 245px;
    background-color: darkred;
    color: white;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(50% 20%, 100% 10%, 100% 90%, 50% 100%, 0% 90%, 0% 10%);
}

.content {
    /* max-width: 300px; */
    font-size: 16px;
}

/*  */

/* About us chevron bulleting */
.chevron-container1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #2A2742;
}

.chevron-item1 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: -46px;
}

.chevron1 {
    width: 92px;
    height: 260px;
    background-color: #E9E6FA;
    color: #2A2742;
    font-size: 20px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(50% 20%, 100% 10%, 100% 90%, 50% 100%, 0% 90%, 0% 10%);
}

.content1 {
    /* max-width: 300px; */
    /* font-size: 16px; */
    color: #2A2742;
}

/*  */

/* Pyramid */
.pyramid_container {
    display: flex;
    align-items: center;
    gap: 30px;
    /* Space between pyramid and text */
}

.pyramid {
    position: relative;
    width: 100%;
    height: 238px;
}

.pyramid_layer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid #a52a2a;
    background-color: #800000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pyramid_layer:nth-child(1) {
    width: 145px;
    height: 110px;
    top: 0;
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
}

.pyramid_layer:nth-child(2) {
    width: 287px;
    height: 110px;
    top: 113px;
    clip-path: polygon(50% -101%, 100% 100%, 0% 100%);
}

.pyramid_layer:nth-child(3) {
    width: 430px;
    height: 110px;
    top: 227px;
    clip-path: polygon(50% -202%, 100% 100%, 0% 100%);
}

.pyramid_text-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.pyramid_text-box {
    display: flex;
    flex-direction: column;
}

.pyramid_text-box h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.pyramid_text-box p {
    font-size: 14px;
    margin: 0;
}

/*  */